<template>
  <div>
    <b-overlay :show="isBusy" rounded="sm">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="secondary" />
          <b-spinner type="grow" variant="dark" />
          <b-spinner small type="grow" variant="secondary" />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Veuiller patienter...</span>
        </div>
      </template>
      <hr />
      <b-alert v-if="this.id !== '' && cantLoadData" variant="danger" show>
        <h4 class="alert-heading">Erreur</h4>
        <div class="alert-body">
          <span>{{ cantLoadDataText }}</span>
        </div>
      </b-alert>
      <h5 v-if="this.id !== '' && !cantLoadData" class="">
        <span v-if="this.formdata.slug !== ''">
          {{ `Modification de données :: ${this.formdata.slug}` }}
        </span>
        <span v-else>
          {{ `Enregistrer` }}
        </span>

        <small class="text-danger align-right">* Champs obligatoire</small>
        <hr />
      </h5>

      <b-card>
        <b-tabs content-class="mt-2" vertical justified>
          <b-tab
            active
            :title="
              this.formdata.slug !== ''
                ? 'Modifier profil'
                : 'Enregistrer un compte'
            "
          >
            <h2>Informations Personnelles</h2>
            <hr />
            <b-form @submit.prevent="save">
              <b-row>
                <b-col md="6">
                  <!-- GRADE, NOM & PRENOM(S)  -->
                  <b-row>
                    <!-- GRADE -->
                    <b-col md="4">
                      <b-form-group label="* Grade" label-for="grade">
                        <v-select
                          id="grade"
                          v-model="formdata.grade"
                          label="text"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Sélectionner un grade"
                          :options="grades"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- NOM -->
                    <b-col md="8">
                      <b-form-group label="* Nom" label-for="lastname">
                        <b-form-input
                          v-on:keypress="onLetterChange"
                          id="lastname"
                          v-model="formdata.lastname"
                          placeholder="Nom"
                          required
                        />
                      </b-form-group>
                    </b-col>

                    <!-- PRENOM(S) -->
                    <b-col md="12">
                      <b-form-group label="* Prénom(s)" label-for="firstname">
                        <b-form-input
                          v-on:keypress="onLetterChange"
                          id="firstname"
                          v-model="formdata.firstname"
                          placeholder="Prénom(s)"
                          required
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- TELEPHONE -->
                  <b-row>
                    <!-- TELEPHONE -->
                    <b-col md="6">
                      <b-row>
                        <b-col>
                          <b-form-group
                            label="* Numéro de téléphone"
                            label-for="contact"
                          >
                            <b-input-group>
                              <b-input-group-prepend is-text>
                                +225
                              </b-input-group-prepend>
                              <cleave
                                id="contact"
                                v-model="formdata.contact"
                                class="form-control"
                                :raw="false"
                                :options="{ phone: true }"
                                placeholder="0709080706"
                                maxlength="10"
                              />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <!-- FONCTION -->
                    <b-col md="6">
                      <b-form-group label="* Fonction" label-for="occupation">
                        <b-form-input
                          id="occupation"
                          v-model="formdata.occupation"
                          placeholder="Ex: Assistant Informatique"
                          maxlength="70"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- USERNAME, EMAIL -->
                  <b-row>
                    <!-- USERNAME -->
                    <b-col md="6">
                      <b-form-group label="* Pseudo" label-for="username">
                        <b-form-input
                          id="username"
                          v-model="formdata.username"
                          placeholder="Ex: grejord"
                          maxlength="70"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- EMAIL -->
                    <b-col md="6">
                      <b-form-group label="* Adresse e-mail" label-for="email">
                        <b-form-input
                          id="email"
                          v-model="formdata.email"
                          placeholder="Ex: grejord@demo.com"
                          maxlength="70"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="12"><hr /></b-col>
                    
                  </b-row>
                  <b-row>
                    <!-- Action -->
                    <b-col md="6">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class=""
                        style=""
                        :disabled="disableButtonOnLoading"
                      >
                        <span v-if="!isLoading">Enregistrer</span>
                        <span v-if="isLoading">
                          <b-spinner small type="grow" />
                          Chargement...
                        </span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
          </b-tab>
          <!-- <b-tab title="Modifer photo de profil">
            <edit-photo :user="formdata" />
          </b-tab> -->
          <b-tab title="Modifier mot de passe">
            <edit-password :user="formdata" />
          </b-tab>
          <b-tab title="Gérer les accès">
            <b-card-text>
              Biscuit powder jelly beans. Lollipop candy canes croissant icing
              chocolate cake. Cake fruitcake powder pudding pastry.I love
              caramels caramels halvah chocolate bar. Cotton candy gummi bears
              pudding pie apple pie cookie.
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BAvatar,
  BFormDatepicker,
  BOverlay,
  BAlert,
  BInputGroupPrepend,
  BInputGroup,
  BTabs,
  BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import UserStore from "@/store/app/user/";
import * as payloads from "./payloads.json";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.ci";

import { grades } from "@/utils/constants";
import EditPhoto from "./edit-photo.vue";
import EditPassword from "./edit-password.vue";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    EditPhoto,
    EditPassword,

    FormWizard,
    TabContent,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BAvatar,
    BFormDatepicker,
    vSelect,
    BOverlay,
    BAlert,
    BInputGroupPrepend,
    BInputGroup,
    Cleave,
    BTabs,
    BTab,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);

    return {
      id: null,
      isBusy: false,
      cantLoadData: false,
      cantLoadDataText: payloads.cantLoadDataText,
      isLoading: false,
      isLoadingQuartiers: false,
      payloads: {},
      // SAVE/EDIT FORM
      grades: grades,
      formdata: {
        slug: null,
        // -FORM-PROPERTIES-
        grade: "",
        username: "",
        email: "",
        lastname: "",
        firstname: "",
        occupation: "",
        contact: "",
        adress: "",
        file: null,
      },
      dateIdData: {
        form: {
          date: "",
          hour: "",
          min: "",
          max: minDate,
        },
        options: {
          time: {
            time: true,
            timePattern: ["h", "m"],
          },
        },
      },
      newDateBirthdateData: {
        form: {
          date: "",
          hour: "",
          min: "",
          max: `${now.getFullYear() - 18}-01-01`,
        },
        options: {
          time: {
            time: true,
            timePattern: ["h", "m"],
          },
        },
      },
    };
  },
  created() {
    /* ----------------------------------------
    | Build payloads on component ready!
      ---------------------------------------- */
    // Build store
    this.buildStore();
    // Build payloads
    this.buildPayloads();

    // Build stranger fucntions
    this.buildStrangers();

    // Build store data
    if (this.id !== "") {
      this.buildStoreData();
    }
    console.log('formdata.grade', this.formdata.grade)
  },
  computed: {
    getCommunes() {
      const { communes } = this.$store.state.UserStore;

      return communes === undefined ? [] : communes;
    },
    getQuartiers() {
      const { quartiers } = this.$store.state.UserStore;

      return quartiers === undefined ? [] : quartiers;
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true;
      }
      return false;
    },
  },
  methods: {
    /* ----------------------------------------
    | APP STORE
      ---------------------------------------- */
    buildStore() {
      if (!UserStore.isRegistered) {
        this.$store.registerModule("UserStore", UserStore);
        UserStore.isRegistered = true;
      }
    },
    /* ----------------------------------------
    | APP PAYLOADS
      ---------------------------------------- */
    buildPayloads() {
      let { path } = this.$router.history.current;
      const getLastItem = (thePath) =>
        thePath.substring(thePath.lastIndexOf("/") + 1);
      path = "user";

      this.payloads = {
        route: `apps-${path}`,
        paths: {
          select: `/api/${path}?method=select`,
          save: `/api/${path}?method=save`,
          edit: `/api/${path}?method=edit`,
          remove: `/api/${path}?method=remove`,
        },
      };
    },
    buildStoreData() {
      this.isBusy = true;
      this.$store
        .dispatch("UserStore/fetch", this.payloads.paths.select)
        .then(async (Q) => {
          this.isBusy = false;

          let fetchAll = Q.filter((data) => data.slug == this.id);
          if (fetchAll.length > 0) {
            let selectedItem = fetchAll[0];

            // GRADE HANDLER
            let gradeFilter = grades[selectedItem.grade-1]
            selectedItem.grade = gradeFilter ? gradeFilter : null;

            // this.formdata = selectedItem;
            this.formdata.slug = selectedItem.slug;
            this.formdata.grade = selectedItem.grade;
            this.formdata.username = selectedItem.username;
            this.formdata.email = selectedItem.email;
            this.formdata.lastname = selectedItem.lastname;
            this.formdata.firstname = selectedItem.firstname;
            this.formdata.occupation = selectedItem.occupation;
            this.formdata.contact = selectedItem.contact;
            this.formdata.adress = selectedItem.adress;
          } else {
            console.log("fetchAll", fetchAll);
            this.cantLoadData = true;
          }
        })
        .catch((e) => {
          console.log('e', e)
          this.isBusy = false;
          this.cantLoadData = true;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Erreur",
              icon: "WarningIcon",
              variant: "danger",
              text: "Impossible de charger la liste des données. Merci de réessayer plutard.",
            },
          });
        });
    },
    buildStrangers() {
      this.buildId();
    },
    buildId() {
      this.id = this.$route.params.id ? this.$route.params.id : "";
    },

    save() {
      if (this.formdata.grade.key === "") {
        this.swalError("Erreur", this.buildRequiredMessage("grade"), "error");
      } else if (!this.formdata.contact) {
        this.swalError(
          "Erreur",
          this.buildRequiredMessage("contact"),
          "error"
        );
      } else {
        let global = {};

        this.formdata.grade = this.formdata.grade.key;

        const formData = this.formdata;

        global = {
          formdata: formData,
          path: this.payloads.paths.edit,
        };
        
        this.isLoading = true;

        this.$store
            .dispatch("UserStore/edit", global)
            .then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Action Réussie",
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: "Modification effectué avec succès!",
                },
              });
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);

              this.swalError("Erreur", error.message, "error");
            });
      }
    },
    buildRequiredMessage(field) {
      return `Le champ ${field} est obligatoire`;
    },
    swalError(title, message, type) {
      this.$swal({
        title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    onLetterChange(e) {
      var keyCode = e.keyCode ? e.keyCode : e.which;
      if (keyCode > 47 && keyCode < 58) {
        e.preventDefault();
      } else if (/@/.test(e.key)) {
        e.preventDefault();
      } else if (/{/.test(e.key) || /}/.test(e.key)) {
        e.preventDefault();
      } else if (/\//.test(e.key) || /\*/.test(e.key) || /\\/.test(e.key)) {
        e.preventDefault();
      }
    },
    // MODAL FORM TOOL --------------
    resetValues() {
      let current = this.payloads;

      for (var key in current) {
        if (current.hasOwnProperty(key)) {
          if (typeof current[key] === "string") {
            current[key] = "";
          } else if (typeof current[key] === "number") {
            current[key] = 0;
          } else if (typeof current[key] === "object") {
            current[key] = {};
          } else if (current[key] instanceof Array) {
            current[key] = [];
          } else {
            current[key] = "";
          }
        }
      }
      this.payloads = current;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.align-right {
  float: right;
}
.block-separator {
  border-left: 2px dotted #000;
}
</style>