<template>
  <div>
    <h2>Photo de profil</h2>
    <hr />
    <b-form @submit.prevent="save">
      <b-row>
        <b-col md="4">
          <b-form-group label="Photo" label-for="file">
            <b-form-file
              id="file"
              v-model="form.file"
              accept="images/*"
              placeholder="Sélectionner un fichier"
              @change="this.$coddyger.updateCurrFile"
            />
            <a
              v-if="form.file"
              class="text text-danger"
              @click="emptyFileInput"
              ><small>Retirer fichier</small></a
            >
          </b-form-group>
        </b-col>
        <b-col md="4">
          <img :src="user.img_path || buildPhoto" />
        </b-col>
      </b-row>
      <b-row>
        <!-- Action -->
        <b-col md="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class=""
            style=""
            :disabled="disableButtonOnLoading"
          >
            <span v-if="!isLoading">Enregistrer</span>
            <span v-if="isLoading">
              <b-spinner small type="grow" />
              Chargement...
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormFile,
  BOverlay,
  BAlert,
  BInputGroupPrepend,
  BInputGroup,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserStore from "@/store/app/user/";
import Ripple from "vue-ripple-directive";

export default {
  name: "edit-photo",
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormFile,
    vSelect,
    BOverlay,
    BAlert,
    BInputGroupPrepend,
    BInputGroup,
    BSpinner,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        slug: null,
        file: null,
      },
    };
  },
  created() {
    /* ----------------------------------------
    | Build payloads on component ready!
      ---------------------------------------- */
    // Build store
    this.buildStore();
  },
  computed: {
    buildPhoto() {
        let img = this.$store.state.UserStore.photo
        this.user.img_path = img
        return img
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true;
      }
      return false;
    },
  },
  methods: {
    /* ----------------------------------------
    | APP STORE
      ---------------------------------------- */
    buildStore() {
      if (!UserStore.isRegistered) {
        this.$store.registerModule("UserStore", UserStore);
        UserStore.isRegistered = true;
      }
    },

    save() {
      if (this.form.file === null) {
        this.swalError("Erreur", "Veuiller sélectionner un fichier", "error");
      } else {
        let global = {};

        let formData = new FormData();

        formData.append("slug", this.user.slug);
        formData.append("file", this.form.file);

        global = {
          type: "form-data",
          formdata: formData,
          path: "api/user?method=edit-avatar",
        };

        this.isLoading = true;

        this.$store
          .dispatch("UserStore/editPhoto", global)
          .then(() => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Action Réussie",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Modification effectué avec succès!",
              },
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);

            this.swalError("Erreur", error.message, "error");
          });
      }
    },

    swalError(title, message, type) {
      this.$swal({
        title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    emptyFileInput() {
      this.form.file = null;
    },
  },
};
</script>
